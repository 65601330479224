(function() {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('AuditsController', AuditsController);

    AuditsController.$inject = ['$state', 'AuditsService', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function AuditsController($state, AuditsService, ParseLinks, AlertService, paginationConstants, pagingParams) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;


        vm.loadAll = loadAll;


        loadAll();

        function loadAll() {
            if (pagingParams.search) {
                // TODO search audit
                //DoctorSearch.query({
                //    query: pagingParams.search,
                //    page: pagingParams.page - 1,
                //    size: vm.itemsPerPage,
                //    sort: sort()
                //}, onSuccess, onError);
            } else {
                AuditsService.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.audits = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')

            });
        }

    }
})();
