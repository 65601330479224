(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('UserManagementDetailController', UserManagementDetailController);

    UserManagementDetailController.$inject = ['$rootScope', '$scope', '$interval', '$stateParams', '$translate', '$window', 'User', 'AuditsService', 'PersistentToken', 'Principal'];

    function UserManagementDetailController($rootScope, $scope, $interval, $stateParams, $translate, $window, User, AuditsService, PersistentToken, Principal) {
        var vm = this;

        const userId = $stateParams.id;

        $translate('continuumplatformApp.user.detail.title', {id: userId}).then(title => {
            $window.document.title = title;
        });

        vm.enable = enable;
        vm.disable = disable;
        vm.unlock = unlock;
        vm.invalidateToken = invalidateToken;
        vm.changeSelectedAuthority = changeSelectedAuthority;
        vm.addAuthority = addAuthority;
        vm.removeAuthority = removeAuthority;
        vm.logSize = 5;
        vm.user = {};
        const baseAuthorities = [
            'ROLE_ADMIN',
            'ROLE_PRACTITIONER',
            'ROLE_ARTICLE51_BILL',
            'ARTICLE51_BILL_19GRE08',
            'ARTICLE51_REPORT_19GRE08',
            'ONCOLINK_EXPETO',
            'ROLE_FINANCE',
            'ROLE_EDITOR',
            'ROLE_SECURITY',
            'ROLE_REPORT',
            'ROLE_SYSTEM',
            'LIQUIBASE',
            'REPORT_GLOBAL',
            'SEND_MESSAGE'];
        vm.practitionerAuthorities = [
            'PATIENT_SEARCH_CONNECTOR_BY_CRITERIA',
            'PATIENT_HEALTH_READ',
            'PATIENT_THERAPY_REQUEST',
            'PATIENT_THERAPY_MANAGE',
            'PATIENT_THERAPY_EXPORT',
            'PATIENT_THERAPY_DELETE',
            'MODIFY_TREATMENT',
            'PHARM_ANALYSIS',
            'PRESCRIPTION',
            'BILL',
            'SIGN',
            'PORTABILITY'
        ];
        vm.readonlyAuthorities = [
            'ROLE_PATIENT'
        ];
        vm.allAuthorities = [...vm.readonlyAuthorities, ...baseAuthorities, ...vm.practitionerAuthorities];
        vm.selectedAuthority = null;
        vm.availableAuthorities = [];

        Principal.identity().then(function (account) {
            vm.currentAccount = account;
        });

        load();
        var intervalPromise = $interval(function () {
            load();
        }, 60000);

        var unsubscribe1 = $rootScope.$on('continuumplatformApp:userUpdate', load);
        $scope.$on('$destroy', function () {
            unsubscribe1();
            $interval.cancel(intervalPromise);
        });

        function load() {
            User.getSensitive({id: userId}, function (result) {
                vm.user = result;
                vm.userIsPractitioner = vm.user.authorities.includes('ROLE_PRACTITIONER');
                vm.availableAuthorities = vm.allAuthorities
                    .filter(authority => !vm.user.authorities.includes(authority))
                    .filter(authority => !vm.readonlyAuthorities.includes(authority))
                    .filter(authority => !vm.practitionerAuthorities.includes(authority) || vm.userIsPractitioner);
                loadAudit();
                loadTokens();
            });
        }

        function loadAudit() {
            AuditsService.query({
                principal: vm.user.login,
                sort: "auditEventDate,desc",
                size: vm.logSize
            }, function (data) {
                vm.audits = data;
            });
        }

        function loadTokens() {
            PersistentToken.query({
                "userId.equals": vm.user.id,
                sort: "tokenDate,desc"
            }, function (tokens) {
                vm.tokens = tokens;
            });
        }

        function invalidateToken(series) {
            PersistentToken.delete({
                id: encodeURIComponent(series)
            }, function () {
                loadTokens();
            });
        }

        function enable() {
            User.enable({id: userId}, {}, function (result) {
                vm.user = result;
            });
        }

        function disable() {
            User.disable({id: userId}, {}, function (result) {
                vm.user = result;
            });
        }

        function unlock() {
            User.unlock({id: userId}, {}, function (result) {
                vm.user = result;
            });
        }

        function changeSelectedAuthority() {
            addAuthority(vm.selectedAuthority);
            vm.selectedAuthority = null;
        }

        function addAuthority(authority) {
            User.addAuthority({id: userId, authority}, {}).$promise.then(load);
        }

        function removeAuthority(authority) {
            User.removeAuthority({id: userId, authority}).$promise.then(load);
        }

    }
})();
