(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .factory('AuditsService', AuditsService);

    AuditsService.$inject = ['$resource', 'DateUtils'];

    function AuditsService($resource, DateUtils) {
        var service = $resource('management/audits/:id', {}, {
            'get': {
                method: 'GET',
                isArray: true
            },
            'getLastSuccesfulAuthentication': {
                method: 'GET',
                params: {
                    "lastSuccessfulAuthentication": true
                },
                transformResponse: function (data) {
                    if (data) {
                        data = {date: DateUtils.convertDateTimeFromServer(angular.fromJson(data))};
                    }
                    return data;
                }
            },
            'query': {
                method: 'GET',
                isArray: true
            }
        });

        return service;
    }
})();
